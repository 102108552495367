@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';

@keyframes rotate-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  transform: rotate(360deg);
  animation: rotate-loader 1s linear infinite;
  height: 2.5rem;
  margin: 2.5rem auto 1.875rem;
  display: block;
}

// .historyWrapper {
  // overflow-y: scroll;
  // height: 12.5rem;
// }

.noHistory {
  text-align: center;
}

.row {
  display: grid;
  font-family: $HAFFER;
  font-size: .75rem;
  padding: 1rem 0;
  // border-top: solid .0625rem $DIVIDER_GREY;

  &:last-child {
    border-bottom: solid .0625rem $DIVIDER_GREY;
  }

  @media (min-width: $SCREEN_TABLET) {
    font-size: .875rem;
  }

  @media (min-width: $SCREEN_SM_DESKTOP) {
    font-size:      1rem;
    flex-direction: row;
  }
}

.achRow {
  @extend .row;
  text-align: right;
  grid-template:
    'img description description'
    'details date price';
  // grid-template-rows: 1fr 1fr;
  // grid-template-columns: 1fr 1fr 1fr;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    grid-template: 'img details description date price';
    // grid-template-columns: 19rem 1fr 8rem 6rem;
    grid-template-columns: 3rem 15rem 1fr 6.25rem 7.5rem;
  }

  .bankIcon {
    align-self: flex-start;
    // height: 1.875rem;
    height: 2rem;
    width: auto;
    padding-right: 0.3125rem;
    // justify-self: center;
  }

  .details {
    grid-area: details;
  }

  .date {
    text-align: right;
    @media (min-width: $SCREEN_TABLET) {
      text-align: center;
    }
  }
  .price {
    text-align: right;
  }
}

.details {
  text-align: left;
  grid-area: details;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.description {
  text-align: left;
  grid-area: description;
}

.paymentRow {
  @extend .row;
  grid-row-gap: .625rem;
  grid-template-columns: 5.625rem 1fr 1fr;
  grid-template:
    // 'img details details'
    // 'img description description'
    // 'img date price';
    'img description description'
    'details date price';

  @media (min-width: $SCREEN_SM_DESKTOP) {
    grid-template: 'img details description date price';
    grid-template-columns: 3rem 15rem 1fr 6.25rem 7.5rem;
    align-items: center;
  }

  .smPayPal {
    align-self: flex-start;
    height: 1.875rem;
    width: auto;
  }

  .img {
    grid-area: img;
    align-items: center;
    display: flex;
    // justify-content: center;
    height: 1.5rem;
    padding-right: 0.3125rem;

    img {
      height: 1.5rem;
      width: auto;
    }
  }

  .card {
    align-self: flex-start;
    // height: 1.875rem;
  }

  .details {
    grid-area: details;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    @media (min-width: $SCREEN_SM_DESKTOP) {
      margin-left: .625rem;
      text-align: left;
    }
  }

  .date {
    grid-area: date;
    text-align: right;
    @media (min-width: $SCREEN_SM_DESKTOP) {
      text-align: center;
    }
  }

  .price {
    text-align: right;
  }
}

.price {
  grid-area: price;
}

.inlineProdName {
  font-size: .75rem;
  
  @media (min-width: $SCREEN_TABLET) {
    font-size: .875rem;
  }

  @media (min-width: $SCREEN_SM_DESKTOP) {
    font-size: 1rem;
  }
}